<section class="sessaoRP sessaoSecundary" id="products">
  <div class="container">
    <h2 class="tittle">Produtos e Serviços</h2>
    <div class="row">

      <div class="cardRP col-md-6 col-lg-4">
        <img src="assets/img/mobilidade.png" alt="Usuário segurando um celular">
        <div class="cont-title">
          <img src="assets/img/icon-mobile.png" alt="Icone de um celular">
          <h4>Mobilidade</h4>
        </div>
        <p>Elimine os obstáculos para seus negócios e esteja onde seu cliente necessite. Esta postura ativa dará maiores vantagens competitivas à sua empresa e, consequentemente, um aumento do volume de negócios.</p>
      </div>

      <div class="cardRP col-md-6 col-lg-4">
        <img src="assets/img/seguranca.png" alt="Segurança de dados">
        <div class="cont-title">
          <img src="assets/img/icon-seguranca.png" alt="Icone de segurança">
          <h4>Segurança</h4>
        </div>
        <p>Todos os dados enviados entre os dispositivos e os servidores são criptografados. Além disso, todas as transações são controladas rigorosamente, identificando e impedindo a entrada de transações não autorizadas e/ou fraudulentas.</p>
      </div>

      <div class="cardRP col-md-6 col-lg-4">
        <img src="assets/img/vantagem.png" alt="Vantagem nos terminais">
        <div class="cont-title">
          <img src="assets/img/icon-vantagem.png" alt="Icone de vantagem">
          <h4>Vantagem</h4>
        </div>
        <p>Todas operadoras em um único terminal.</p>
      </div>

      <div class="cardRP col-md-6 col-lg-4">
        <img src="assets/img/titulos-capitalizacao.png" alt="Usuário segurança um cifrão">
        <div class="cont-title">
          <img src="assets/img/icon-vantagem.png" alt="Icone cifrão">
          <h4>Títulos de capitalização (on-line e off-line)</h4>
        </div>
<!--        <p>Lorem Ipsum is simply dummy text of the-->
<!--          printing and typesetting industry. Lorem-->
<!--          Ipsum has been the industry's standard-->
<!--          dummy text ever since the 1500s, when-->
<!--          an unknown printer took a galley of type.</p>-->
      </div>

      <div class="cardRP col-md-6 col-lg-4">
        <img src="assets/img/recarga-celular.png" alt="Recargar via POS">
        <div class="cont-title">
          <img src="assets/img/icon-recarga.png" alt="Icone de recarga">
          <h4>Recarga para celulares</h4>
        </div>
        <p>A <span>REDEPOS</span> oferece por meio de uma plataforma estruturada a venda de recarga para telefones pré-pagos de várias operadoras de telefonia, por meio de terminal POS próprio.</p>
      </div>

      <div class="cardRP col-md-6 col-lg-4 mb-0">
        <img src="assets/img/frente-de-loja.jpg" alt="Frente de loja">
        <div class="cont-title">
          <img src="assets/img/icon-frente.png" alt="Icone frente de loja">
          <h4>Frente de loja</h4>
        </div>
<!--        <p>Lorem Ipsum is simply dummy text of the-->
<!--          printing and typesetting industry. Lorem-->
<!--          Ipsum has been the industry's standard-->
<!--          dummy text ever since the 1500s, when-->
<!--          an unknown printer took a galley of type.</p>-->
      </div>


    </div>
  </div>
</section>
