import {Component, OnInit, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {

  formulario: FormGroup;
  submitForm = false;
  constructor(
    private fb: FormBuilder
  ) { }
  @ViewChild(MatAccordion) accordion: MatAccordion;

  ngOnInit(): void {
    this.createForm();
  }

  // tslint:disable-next-line:typedef
  createForm() {
    this.formulario = this.fb.group({
      nome: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(60)])],
      email: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(80)])],
      telefone: [null, Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])],
      vaga: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(60)])],
      anexo: []
    });
  }

  // tslint:disable-next-line:typedef
  enviarFormOportunidade() {
    this.submitForm = true;
    if (this.formulario.valid) {
      // Swal('Verifique e preencha os campos obrigatórios e tente novamente.');
      return;
    } else {
      return('Sucesso');
    }
  }
}
