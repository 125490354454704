<section class="sessaoRP sessaoSecundary" id="blog">
  <div class="container">
    <h2 class="tittle">Blog</h2>
    <p class="subtitle">Compartilhando conhecimento <span class="heart">&#10084;</span></p>
    <div class="row">
      <div class="col-md-4 mb-5">
        <mat-card class="example-card">
          <img mat-card-image src="assets/img/blog/python.png" alt="Linguagem Python">
          <mat-card-content>
            <h4>Estruturas de repetição em Python</h4>
            <h5>Como utilizar laços de repetição</h5>
            <p>
              As linguagens de programação normalmente
              suportam tanto estruturas condicionais como
              também os chamados laços de repetição,
              estruturas que permitem a execução de instruções
              repetidas vezes, até que uma condição seja
              atingida.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button class="btn-rp">Leia mais</button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-md-4 mb-5">
        <mat-card class="example-card">
          <img mat-card-image src="assets/img/blog/git.png" alt="Git">
          <mat-card-content>
            <h4>Git</h4>
            <h5>Desfazendo mudanças locais sem prejudicar o código</h5>
            <p>
              Até que você "dê push" em alterações locais, elas
              estarão disponíveis apenas para você, e existem
              algumas maneiras diferentes de reverter mudanças
              em um repositório Git.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button class="btn-rp">Leia mais</button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-md-4 mb-5">
        <mat-card class="example-card">
          <img mat-card-image src="assets/img/blog/angular.png" alt="Angular">
          <mat-card-content>
            <h4>Angular Filter e pipes</h4>
            <h5>Utilizando datas, moedas e valores monetários</h5>
            <p>
              As linguagens de programação normalmente
              suportam tanto estruturas condicionais como
              também os chamados laços de repetição,
              estruturas que permitem a execução de instruções
              repetidas vezes, até que uma condição seja
              atingida.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button class="btn-rp">Leia mais</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

  </div>
</section>
