<section class="sessaoO" id="contact">
  <div class="bgrp">
    <img class="bgleft" src="assets/img/bgleft.png" alt="Forma geométrica da RedePos">
    <img class="bgright" src="assets/img/bgright.png" alt="Forma geométrica da RedePos">
  </div>
  <div class="container sessaoRP">
    <div class="row">
      <div class="contact col-md-6">
        <h2>Contato</h2>
        <p>Quer trocar uma ideia? Fala aí com a gente!</p>
        <p>contato@redepos.com.br</p>
        <!--        <form id="form-contato" (ngSubmit)="enviarFormContato()" [formGroup]="formContato">-->
<!--          <div class="formRP">-->
<!--            <label for="nome">Nome</label>-->
<!--            <input type="text" id="nome" name="nome" formControlName="nome">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="email">E-mail</label>-->
<!--            <input type="text" id="email" name="email" formControlName="email">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="telefone">Telefone</label>-->
<!--            <input type="text" id="telefone" name="telefone" formControlName="telefone">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="msg">Mensagem</label>-->
<!--            <textarea id="msg" name="msg" rows="4" cols="50" formControlName="msg"></textarea>-->
<!--          </div>-->
<!--          <div class="cont-button">-->
<!--            <button class="btn-rp-2" type="button" (click)="enviarFormContato()">Enviar</button>-->
<!--          </div>-->
<!--        </form>-->
      </div>
      <div class="where col-md-6">
        <h2>Onde estamos?</h2>
        <p><span class="material-icons icon-location">location_on</span> Avenida caxangá, 3425, Loja 2, Iputinga, Recife/PE. CEP 50670-000</p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.58046283792!2d-34.93882468522018!3d-8.042114994209156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab196cf037b645%3A0xc9d2bad3b0053e5c!2sRedepos%20Telefonia%2C%20Com%C3%A9rcio%20de%20Equipamentos%20e%20Promo%C3%A7%C3%A3o%20de%20Vendas%20LTDA!5e0!3m2!1spt-BR!2sbr!4v1610045996181!5m2!1spt-BR!2sbr" width="100%" height="420" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div>
  </div>
</section>
